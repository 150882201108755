<template>
	<div>
		<!-- {{data}} -->
		<table width="100%" class="detail-table table-head-sticky">
			<thead>
				<tr>
					<th v-if="status != 'complete'" class="p-2 light-blue-bg text-center custome-table" width="5%">
						<v-checkbox
							dense
							v-model="checkAll"
							:ripple="false"
							hide-details
							color="blue darken-4"
							class="m-0 p-0"
						></v-checkbox>
					</th>
					<th class="p-2 light-blue-bg custome-table" width="25%">Time</th>
					<th class="p-2 light-blue-bg custome-table" width="30%">Title</th>
					<th class="p-2 light-blue-bg custome-table" width="20%">Description</th>
					<th class="p-2 light-blue-bg text-end custome-table" width="35%">Action</th>
				</tr>
			</thead>

			<tbody v-if="reminderData">
				<!-- <pre>{{ reminderData }}</pre>	 -->
				<template v-for="(rows, index) in reminderData">
					<tr :key="index" :class="index ? 'active_class' : ''">
						<td colspan="5" class="p-2 border-top-light-grey">
							<v-icon color="blue" class="me-1" small>mdi-calendar</v-icon>
							<span
								class="font-level-2-bold text-capitalize"
								v-if="index === 'today' || index === 'yesterday'"
								>{{ index }}</span
							>
							<span class="font-level-2-bold text-capitalize" v-else>{{ formatDate(index) }}</span>
						</td>
					</tr>
					<template v-for="(row, index) in rows">
						<tr :key="index">
							<td
								v-if="status != 'complete'"
								class="p-2 border-top-light-grey custome-table-td text-center"
							>
								<v-checkbox
									dense
									:ripple="false"
									:value="row.id"
									hide-details
									v-model="selectedRows"
									multiple
									@change="selectRow"
									class="m-0 p-0"
									color="blue darken-4"
								></v-checkbox>
							</td>

							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="d-flex align-center">
									<v-icon small>mdi-clock-outline</v-icon>
									<span
										class="font-weight-bold text-h5 ms-1"
										v-if="row && row.start_date"
										:color="getStatusColor()"
									>
										{{ formatTime(row.start_date) }}
										<!-- <Chip
									:text="formatDateTime(row.start_date)"
									text-color=""
									outlined
									small
									:color="getStatusColor()"
									v-if="row.start_date"
								></Chip> -->
									</span>
									<v-spacer></v-spacer>
									<span class="ml-2" v-if="row && row.type">
										<Chip
											:text="getReminderType(row.type)"
											text-color=""
											outlined
											:color="getActiveColor(row.type)"
											v-if="getReminderType(row.type)"
										></Chip>
									</span>
								</div>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td">
								<div class="d-flex">
									<div class="d-flex align-center mb-0">
										<span class="">
											<div v-if="row.callType == 'call'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs">
															<v-chip class="px-1" label color="green">
																<v-icon color="white">mdi-phone</v-icon>
															</v-chip>
														</div>
													</template>
													<span>Call</span>
												</v-tooltip>
											</div>
											<div v-if="row.callType == 'email'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs">
															<v-chip class="px-1" label color="red">
																<v-icon color="white">mdi-email</v-icon>
															</v-chip>
														</div>
													</template>
													<span>Email</span>
												</v-tooltip>
											</div>
											<div v-if="row.callType == 'other'">
												<v-tooltip top content-class="custom-top-tooltip">
													<template v-slot:activator="{ on, attrs }">
														<div v-on="on" v-bind="attrs">
															<v-chip class="px-1" label color="cyan">
																<v-icon color="white" small>mdi-dots-horizontal-circle-outline</v-icon>
																<!-- <span class="white--text">O</span> -->
															</v-chip>
														</div>
														<!-- <v-badge v-on="on" v-bind="attrs" color="cyan" content="O"></v-badge> -->
													</template>
													<span>Other</span>
												</v-tooltip>
											</div>
										</span>
										<span v-if="row && row.title" class="font-level-3-bold ms-2 text-capitalize">
											{{ row.title }}
										</span>
										<em class="text-muted" v-else>no title</em>
									</div>
									<v-spacer></v-spacer>
									<div
										v-if="row.related_to == 'task'"
										class="cursor-pointer"
										v-on:click.stop.prevent="relatedPage(row)"
									>
										<Chip
											:text="row.task_barcode"
											small
											color="blue"
											text-color=""
											outlined
											v-if="row.task_barcode"
										></Chip>
									</div>
									<div
										v-if="row.related_to == 'lead'"
										class="cursor-pointer"
										v-on:click.stop.prevent="relatedPage(row)"
									>
										<span class="text-capitalize" v-if="row?.lead_name">
											{{ row.lead_name }}
										</span>
										<span class="text-capitalize" v-else>
											{{ row.lead_customer_name }}
										</span>
										<!-- <Chip
											:text="row.lead_barcode"
											small
											color="green"
											text-color=""
											outlined
											v-if="row.lead_barcode"
										></Chip> -->
									</div>
									<div
										v-if="row.related_to == 'meeting'"
										class="cursor-pointer"
										v-on:click.stop.prevent="relatedPage(row)"
									>
										<span class="text-capitalize">
											{{ row.meeting_title }}
										</span>
										<!-- <Chip
											:text="row.meeting_title"
											small
											color="red"
											text-color=""
											outlined
											v-if="row.meeting_title"
										></Chip> -->
									</div>
									<div
										v-if="row.related_to == 'customer'"
										class="cursor-pointer"
										v-on:click.stop.prevent="relatedPage(row)"
									>
										<span class="text-capitalize">
											{{ row.customer_company_name }}
										</span>
										<!-- <Chip
											:text="row.customer_barcode"
											small
											color="orange"
											text-color=""
											outlined
											v-if="row.customer_barcode"
										></Chip> -->
									</div>
									<span class="ml-2">
										<div v-if="row.related_to == 'task'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div v-on="on" v-bind="attrs">
														<v-avatar color="green" size="20">
															<span class="white--text">T</span>
														</v-avatar>
													</div>
												</template>
												<span>Task</span>
											</v-tooltip>
										</div>
										<div v-if="row.related_to == 'lead'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div v-on="on" v-bind="attrs">
														<v-avatar color="green" size="20">
															<span class="white--text">L</span>
														</v-avatar>
													</div>
												</template>
												<span>Lead</span>
											</v-tooltip>
											<!-- <v-badge color="green" content="L"></v-badge> -->
										</div>
										<div v-if="row.related_to == 'meeting'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div v-on="on" v-bind="attrs">
														<v-avatar color="red" size="20">
															<span class="white--text">M</span>
														</v-avatar>
													</div>
												</template>
												<span>Meeting</span>
											</v-tooltip>
											<!-- <v-badge color="red" content="M"></v-badge> -->
										</div>
										<div v-if="row.related_to == 'customer'">
											<v-tooltip top content-class="custom-top-tooltip">
												<template v-slot:activator="{ on, attrs }">
													<div v-on="on" v-bind="attrs">
														<v-avatar color="orange" size="20">
															<span class="white--text">C</span>
														</v-avatar>
													</div>
												</template>
												<span>Customer</span>
											</v-tooltip>
										</div>
									</span>
								</div>
							</td>

							<td class="p-2 border-top-light-grey custome-table-td">
								<span v-if="row && row.description" class="d-flex">
									<!-- <div class="" v-html="row.description"></div> -->
									<span class="desc_para" v-html="truncate(row?.description, 20)"></span>
									<v-btn
										small
										plain
										text
										color="cyan"
										class="font-weight-700"
										@click="showDescription(row.description)"
										>Read More</v-btn
									>
								</span>
								<em v-else class="text-muted"> no description</em>
							</td>
							<td class="p-2 border-top-light-grey custome-table-td text-end">
								<div class="">
									<template v-if="status != 'complete'">
										<v-tooltip top min-width="150" right content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													v-bind="attrs"
													v-on="on"
													depressed
													fab
													dark
													x-small
													color="green"
													class="mr-3 custom-disabled"
													@click="completeReminder(row)"
												>
													<v-icon>mdi-check</v-icon>
												</v-btn>
											</template>
											<span>Mark as Completed</span>
										</v-tooltip>
										<v-tooltip top content-class="custom-top-tooltip">
											<template v-slot:activator="{ on, attrs }">
												<v-btn
													depressed
													fab
													dark
													x-small
													color="blue"
													v-bind="attrs"
													v-on="on"
													class="mr-3 custom-disabled"
													@click="updateReminder(row)"
												>
													<v-icon>mdi-pencil</v-icon>
												</v-btn>
											</template>
											<span>Edit</span>
										</v-tooltip>
									</template>
									<v-tooltip top content-class="custom-top-tooltip">
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												depressed
												fab
												dark
												x-small
												color="red"
												v-bind="attrs"
												v-on="on"
												class="mr-3 custom-disabled"
												@click="deleteReminder(row)"
											>
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</template>
										<span>Delete</span>
									</v-tooltip>
								</div>
							</td>
						</tr>
					</template>
				</template>
			</tbody>
			<tfoot v-else>
				<tr>
					<td colspan="7">
						<p class="m-0 row-not-found text-center py-3">
							<img :src="$assetURL('media/error/empty.png')" class="row-not-found-image" />
							Uhh... There <span class="text-lowercase">are no reminders at the moment.</span>
						</p>
					</td>
				</tr>
			</tfoot>
		</table>
		<CreateOrUpdateReminderV2
			v-if="open_dialog_reminder"
			:drawer="open_dialog_reminder"
			:reminderId="editReminderId"
			v-on:success="refreshReminder"
			v-on:close="closeReminderDialog"
		></CreateOrUpdateReminderV2>
		<!-- v-on:deleteSuccess="deleteRefreshReminder($event)" -->
		<DeleteTemplate
			type="Reminder"
			:deleteUrl="deleteUrl"
			:deleteText="deleteText"
			:deleteDialog="confirm_dialog"
			v-on:close="confirm_dialog = false"
			v-on:success="(confirm_dialog = false), refreshReminder()"
		></DeleteTemplate>
		<ReminderCompleteTemplate
			type="Reminder"
			:completeUrl="completeURL"
			:completeText="completeTEXT"
			:count="count"
			:completeDialog="complete_dialog"
			v-on:close="complete_dialog = false"
			v-on:success="(complete_dialog = false), refreshReminder()"
			:dataObject="selectedReminder"
		></ReminderCompleteTemplate>
		<Dialog :dialog="desc_dialog" :dialog-width="600">
			<template v-slot:title> Description</template>
			<template v-slot:body>
				<v-row class="delete-dialog">
					<v-col md="2" class="py-0 text-right my-auto">
						<span class="svg-icon svg-icon-lg delete-confirm-icon">
							<!--begin::Svg Icon-->
							<inline-svg :src="$assetURL('media/custom-svg/attention-circle.svg')" />
							<!--end::Svg Icon-->
						</span>
					</v-col>
					<v-col md="12" class="py-0 my-auto">
						<div v-html="fullDescription"></div>
					</v-col>
				</v-row>
			</template>
			<template #action>
				<v-btn depressed tile :disabled="pageLoading" @click="desc_dialog = false"> Close </v-btn>
			</template>
		</Dialog>
	</div>
</template>
<script>
import Dialog from "@/view/components/Dialog";
import Chip from "@/view/components/Chip";
import CreateOrUpdateReminderV2 from "@/view/pages/leads/create/CreateOrUpdateReminderV2";
import DeleteTemplate from "@/view/components/DeleteTemplate";
import ReminderCompleteTemplate from "@/view/components/ReminderCompleteTemplate";
// import { GET } from "@/core/services/store/request.module";
export default {
	name: "ReminderTable",
	props: {
		data: {
			type: Object,
			default: () => {
				return {};
			},
		},
		status: {
			type: String,
		},
		selectedIds: {
			type: Array,
			default: () => {
				return [];
			},
		},
	},
	watch: {
		data: {
			deep: true,
			immediate: true,
			handler(param) {
				this.reminderData = param;
			},
		},
		selectedIds: {
			deep: true,
			immediate: true,
			handler(param) {
				this.selectedRows = param;
			},
		},
	},
	data() {
		return {
			desc_dialog: false,
			fullDescription: "",
			selectedReminder: {},
			reminderData: [],
			editReminderId: 0,
			open_dialog_reminder: false,
			relatedId: 0,
			count: null,
			relatedTo: "lead",
			meeting_title: null,
			pageLoading: false,
			confirm_dialog: false,
			deleteUrl: null,
			deleteText: null,
			selectedRows: [],
			complete_dialog: false,
			completeURL: null,
			completeTEXT: null,
		};
	},
	methods: {
		showDescription(description) {
			this.fullDescription = description;
			this.desc_dialog = true;
		},
		truncate(text, length) {
			return text.length > length ? text.substring(0, length) + "..." : text;
		},
		selectRow() {
			this.$emit("selection", this.selectedRows);
		},
		getStatusColor() {
			let color = "blue";
			if (this.status == "complete") {
				color = "green";
			} else if (this.status == "overdue") {
				color = "red";
			}
			return color;
		},
		getReminderType(data) {
			if (data == 1) {
				this.typeColor = "cyan";
				return "One Time Reminder";
			}
			if (data == 2) {
				this.typeColor = "green";
				return "Daily Reminder";
			}
			if (data == 3) {
				this.typeColor = "blue";
				return "Weekly Reminder";
			}
			if (data == 4) {
				this.typeColor = "orange";
				return "Monthly Reminder";
			}
			if (data == 5) {
				this.typeColor = "yellow";
				return "Quarterly Reminder";
			}
			if (data == 6) {
				this.typeColor = "yellow";
				return "Half Yearly Reminder";
			}
		},
		getActiveColor(data) {
			if (data == 1) {
				return "cyan";
			}
			if (data == 2) {
				return "green";
			}
			if (data == 3) {
				return "blue";
			}
			if (data == 4) {
				return "orange";
			}
			if (data == 5) {
				return "pink";
			}
			if (data == 6) {
				return "pink";
			}
		},
		refreshReminder() {
			this.$emit("refresh", true);
		},
		closeReminderDialog() {
			this.editReminderId = 0;
			this.open_dialog_reminder = false;
		},
		updateReminder(data) {
			this.editReminderId = data.id;
			this.open_dialog_reminder = true;
		},
		completeReminder(data) {
			this.selectedReminder = data;
			this.completeTEXT = `(${data.barcode}) ` + (data.title ? data.title : "");
			this.completeURL = `reminder-complete/${data.id}`;
			this.count = data.total_batch_reminders;
			this.complete_dialog = true;
		},
		deleteReminder(data) {
			this.selectedReminder = data;
			this.deleteText = `(${data.barcode}) ` + data.title;
			this.deleteUrl = `reminder/${data.id}`;
			this.confirm_dialog = true;
		},
	},
	components: {
		Dialog,
		Chip,
		CreateOrUpdateReminderV2,
		DeleteTemplate,
		ReminderCompleteTemplate,
	},
	computed: {
		checkAll: {
			set(value) {
				if (value) {
					this.selectedRows = [];

					// Use a Set to automatically handle duplicates
					const uniqueIds = new Set();

					for (const key in this.reminderData) {
						const reminders = this.reminderData[key];
						for (const reminder of reminders) {
							uniqueIds.add(reminder.id); // Add each ID to the Set
						}
					}

					this.selectedRows = Array.from(uniqueIds); // Convert the Set back to an array
				}
				this.$emit("selection", this.selectedRows);
			},
			get() {
				let totalItems = Object.values(this.reminderData).reduce(
					(acc, reminders) => acc + reminders.length,
					0
				);
				return totalItems > 0 ? this.selectedRows.length === totalItems : false;
			},
		},
	},

	/* computed: {
		checkAll: {
			set(value) {
				if (value) {
					this.selectedRows = [];

					for (let i = 0; i < Object.values(this.reminderData).length; i++) {

						const key = Object.keys(this.reminderData)[i]; // Get the key at index i
						const value = this.reminderData[key]
						for (let j = 0; j < value.length; j++){
							this.selectedRows.push(value[j].id);
						}
					}
				} 
				this.$emit("selection", this.selectedRows);
			},
			get() {
				let totalItems = Object.values(this.reminderData).length;
				return totalItems > 0 ? this.selectedRows.length === totalItems : false;
			},
		},
	}, */
};
</script>
